import React from 'react';

import { ExtraSmall } from '../type';

export const tooltipCopy = {
  safeCarsForFamilies: (
    <ExtraSmall>Vehicles that provide good protection for children and passengers in the back seats.</ExtraSmall>
  ),
  pPlaterApproved: (
    <ExtraSmall>
      Some vehicles are prohibited for probationary drivers. See what vehicles you can and can’t drive while on your Ps.
    </ExtraSmall>
  ),
};
