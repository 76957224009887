import axios from 'axios';

const getCmsUrl = () => process.env.NEXT_PUBLIC_CMS_URL;

const fetchVehicleByRego = (numberPlate) => {
  if (!numberPlate) {
    return false;
  }
  return axios.get(`${getCmsUrl()}/api/lookup-vehicle-rego?query=${numberPlate}`);
};

export default fetchVehicleByRego;
