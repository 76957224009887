import { useEffect, useRef } from 'react';

import { useMounted } from '../use-mounted';

const ESCAPE_KEY = 27;

const useClickOutside = (callback, trigger = true) => {
  const mounted = useMounted();
  const ref = useRef();

  const runCallback = () => {
    if (mounted) {
      callback();
    }
  };

  const determineShouldRunCallback = (event) => {
    const clickOutsideElement = !ref.current.contains(event.target);

    if (clickOutsideElement && trigger) {
      return true;
    }

    return false;
  };

  const handleClickOutside = (event) => {
    const shouldRunCallback = determineShouldRunCallback(event);

    if (shouldRunCallback) {
      runCallback();
    }
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === ESCAPE_KEY) {
      runCallback();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [trigger]);

  return ref;
};

export { useClickOutside };
