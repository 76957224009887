import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { useLookupNumberPlate } from '../../hooks/use-lookup-number-plate';
import { PrimaryButton } from '../button';

import { ExampleText, NumberPlateErrorText, NumberPlateTextField, SearchButtonWrapper, TabPanel } from './styles';

const NumberPlateTabPanel = ({ hidden }) => {
  const [numberPlateInput, setNumberPlateInput] = useState('');

  const {
    numberPlateNotFound,
    onSubmitNumberPlate,
    setNumberPlateNotFound,
    submittingNumberPlate,
  } = useLookupNumberPlate();

  const isSubmittingNumberPlate = submittingNumberPlate.current;

  const submitDisabled = !numberPlateInput;

  const submitNumberPlateInput = () => {
    if (submitDisabled) {
      return;
    }

    onSubmitNumberPlate(numberPlateInput);
  };

  const verifyNumberPlateInput = (input) => {
    if (isSubmittingNumberPlate.current) {
      return;
    }

    setNumberPlateNotFound(false);

    const numbersAndLettersRegex = /[a-zA-Z0-9]/g;
    const result = input.match(numbersAndLettersRegex) || [];
    setNumberPlateInput(result.join('').toUpperCase());
  };

  return (
    <TabPanel hidden={hidden}>
      <ExampleText aria-hidden>Enter a Victorian Number Plate</ExampleText>
      <NumberPlateTextField
        label="Enter a Victorian Number Plate"
        inputProps={{ id: 'rego-id-search', maxLength: '6' }}
        InputLabelProps={{
          htmlFor: 'rego-id-search',
        }}
        value={numberPlateInput}
        placeholder="ABC123"
        onChange={(e) => verifyNumberPlateInput(e.target.value)}
        onKeyDown={({ key }) => {
          if (key === 'Enter') {
            submitNumberPlateInput();
          }
        }}
      />

      <NumberPlateErrorText hidden={!numberPlateNotFound}>
        We couldn&apos;t find any results matching <strong>{numberPlateInput}</strong>. Please enter a different
        registration or try searching by Make or Model
      </NumberPlateErrorText>
      <SearchButtonWrapper>
        <PrimaryButton
          disabled={submitDisabled}
          isLoading={isSubmittingNumberPlate && !numberPlateNotFound}
          fullWidth
          onClick={submitNumberPlateInput}
        >
          Search
        </PrimaryButton>
      </SearchButtonWrapper>
    </TabPanel>
  );
};

NumberPlateTabPanel.propTypes = {
  hidden: PropTypes.bool.isRequired,
};

export default NumberPlateTabPanel;
