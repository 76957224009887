import algoliasearch from 'algoliasearch';
import Router from 'next/router';
import React from 'react';
import { InstantSearch } from 'react-instantsearch-dom';
import styled from 'styled-components';
import _ from 'lodash';
import { connect } from 'react-redux';

import ContentMatrix from '../components/content-matrix';
import DiscoveryQuestionsInput from '../components/discovery-questions-input';
import Layout from '../components/layout';
import Search from '../components/search';
import { BREAKPOINTS, CACHE, GLOBAL, PAGE_SLUGS, SECTION_HANDLES, URLS } from '../constants';
import { fetch } from '../utils/api';
import { transformContentMatrix } from '../utils/transform';
import { withAppProps, withAppProviders } from '../utils/withAppProps';

const { ALGOLIA_APPLICATION_ID } = process.env;
const { ALGOLIA_SEARCH_API_KEY } = process.env;
const { ALGOLIA_INDEX_NAME } = process.env;

const searchClient = algoliasearch(`${ALGOLIA_APPLICATION_ID}`, `${ALGOLIA_SEARCH_API_KEY}`, {});

const StyledLayout = styled(Layout)`
  background-image: url(${(props) => props.secondaryBackgroundImageUrl});
  background-repeat: no-repeat;
  background-size: contain;
  @media (min-width: ${BREAKPOINTS.LG}px) {
    background-position: center 140px;
    background-image: url(${(props) => props.backgroundImageUrl});
  }
`;

const StyledHeading = styled.h1`
  display: flex;
  font-family: ${GLOBAL.BRAND_FONT_FAMILY_SECOND};
  justify-content: center;
  margin-top: 38px;
  text-align: center;

  & span {
    font-family: ${GLOBAL.BRAND_FONT_FAMILY};
  }
`;

const navigateToDiscoveryResults = () => {
  Router.push(URLS.RESULTS);
};

const submitInput = (stateValues) => navigateToDiscoveryResults(stateValues);

const Home = ({
  backgroundImageUrl,
  secondaryBackgroundImageUrl,
  contentMatrix,
  compareCarIds,
  filterActions,
  filterState,
}) => {
  const transformedContentMatrix = transformContentMatrix(contentMatrix);

  return (
    <StyledLayout
      isHomePage
      backgroundImageUrl={backgroundImageUrl}
      secondaryBackgroundImageUrl={secondaryBackgroundImageUrl}
      compareCarIds={compareCarIds}
    >
      <StyledHeading>
        <span>how</span>
        safe
        <span>is</span>
        your
        <span>car?</span>
      </StyledHeading>
      <InstantSearch indexName={ALGOLIA_INDEX_NAME} searchClient={searchClient}>
        <Search />
      </InstantSearch>
      <DiscoveryQuestionsInput submitInput={submitInput} filterActions={filterActions} filterState={filterState} />
      <ContentMatrix blocks={transformedContentMatrix} />
    </StyledLayout>
  );
};

const getStaticProps = withAppProps(async (ctx) => {
  const { preview, previewData } = ctx;
  const contentQuery = `{
    entries(slug: "${PAGE_SLUGS.HOME}") {
      ... on ${SECTION_HANDLES.HOME}_${SECTION_HANDLES.HOME}_Entry {
        backgroundImage {
          url
        }
        secondaryBackgroundImage {
          url
        }
        ${ContentMatrix.query}
      }
    }
  }`;
  const contentQueryResponse = await fetch(
    { query: contentQuery },
    { params: { token: preview ? previewData?.previewToken : undefined } }
  );

  const { backgroundImage, secondaryBackgroundImage, contentMatrix } = contentQueryResponse.data.data.entries[0];
  const backgroundImageUrl = _.get(backgroundImage, '[0].url');
  const secondaryBackgroundImageUrl = _.get(secondaryBackgroundImage, '[0].url');

  return {
    props: {
      backgroundImageUrl,
      secondaryBackgroundImageUrl,
      contentMatrix,
    },
    revalidate: CACHE.REVALIDATE,
  };
});

const mapState = (state) => ({
  filterState: {
    selectedPriceRange: state.filters.selectedPriceRange,
    uiSelectedPriceRange: state.filters.uiSelectedPriceRange,
    selectedBodyStyles: state.filters.selectedBodyStyles,
    selectedLifestyles: state.filters.selectedLifestyles,
  },
});

const mapDispatch = (dispatch) => ({
  filterActions: {
    addLifestyle: dispatch.filters.addLifestyle,
    removeLifestyle: dispatch.filters.removeLifestyle,
    updateSelectedBodyStyles: dispatch.filters.updateSelectedBodyStyles,
    updateSelectedPriceRange: dispatch.filters.updateSelectedPriceRange,
    updateUiSelectedPriceRange: dispatch.filters.updateUiSelectedPriceRange,
  },
});

export default withAppProviders(connect(mapState, mapDispatch)(Home));
export { getStaticProps };
