import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import PropTypes from 'prop-types';
import React from 'react';
import { connectSearchBox } from 'react-instantsearch-dom';
import styled from 'styled-components';

import { BREAKPOINTS, SPACE } from '../../constants';
import { navigateToVehiclePage } from '../../utils/api';
import { logTrackingEvent } from '../../utils/tracking';

import { ExampleText, TabPanel } from './styles';
import { ConnectedSearchInput } from './utils';

const StyledTabPanel = styled(TabPanel)`
  margin-bottom: ${SPACE.X3};

  @media (min-width: ${BREAKPOINTS.LG}px) {
    margin-bottom: calc(${SPACE.X2} + ${SPACE.X0});
  }
`;

const searchMakeModel = (make, model) => {
  logTrackingEvent({
    event: 'searchMakeModel',
    make,
    model,
  });

  navigateToVehiclePage(make, model);
};

const onSubmitMakeModel = ({ make, model }) => searchMakeModel(make, model);

const SearchBox = ({ searchTopRef, isMobileDevice, isSearchStalled: isLoading, refine, currentRefinement }) => (
  <ConnectedSearchInput
    onSubmitForOption={onSubmitMakeModel}
    searchTopRef={searchTopRef}
    isMobileDevice={isMobileDevice}
    isLoading={isLoading}
    refine={refine}
    currentRefinement={currentRefinement}
  />
);

const ConnectedSearchBox = connectSearchBox(SearchBox);

const MakeModelTabPanel = ({ searchTopRef, hidden }) => {
  const theme = useTheme();
  const isMobileDevice = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <StyledTabPanel hidden={hidden}>
      <ExampleText>eg. Ford Focus or Mazda CX-5</ExampleText>
      <ConnectedSearchBox
        onSubmitMakeModel={onSubmitMakeModel}
        searchTopRef={searchTopRef}
        isMobileDevice={isMobileDevice}
      />
    </StyledTabPanel>
  );
};

MakeModelTabPanel.propTypes = {
  hidden: PropTypes.bool.isRequired,
  searchTopRef: PropTypes.shape({ current: PropTypes.shape({}) }).isRequired,
};

export default MakeModelTabPanel;
