import { Tab, Tabs, TextField } from '@material-ui/core';
import styled from 'styled-components';

import Icon from '../icons';
import { BOX_SHADOW, BREAKPOINTS, COLORS, FONT_SIZE, GLOBAL, SPACE } from '../../constants';
import { H5, H5Styles } from '../type/h5';

const ICON_SIZE = '17px';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${SPACE.X2};
`;

const SearchIcon = styled(Icon)`
  position: absolute;
  margin-left: ${SPACE.X1};
  margin-right: ${SPACE.X0};
  height: 100%;
  width: ${ICON_SIZE};
`;

const OptionIcon = styled(Icon)`
  min-height: ${ICON_SIZE};
  min-width: ${ICON_SIZE};
  height: ${ICON_SIZE};
  width: ${ICON_SIZE};
  margin: auto;
  margin-right: calc(${SPACE.X1} - 7px);
  position: absolute;
  right: 1rem;

  &:hover: {
    backgroundcolor: ${COLORS.BRAND_BLUE_FOUR};
  }

  &:focus: {
    backgroundcolor: ${COLORS.BRAND_BLUE_FOUR};
  }

  &:active: {
    backgroundcolor: ${COLORS.BRAND_BLUE_THREE};
  }
`;

const StyledTabs = styled(Tabs)`
  .MuiTabs-fixed {
    color: ${COLORS.BLACK};
    background-color: ${COLORS.WHITE};
  }

  .MuiTabs-flexContainer {
    justify-content: space-evenly;
    .MuiButtonBase-root .MuiTouchRipple-root .MuiTouchRipple-child {
      background-color: ${COLORS.GREY_SIX};
    }
  }

  .MuiTabs-indicator {
    background: ${COLORS.BRAND_BLUE_SIX};
    top: 0;
    min-width: 50%;
    height: 4px;
  }
`;

const StyledTab = styled(Tab)`
  &.MuiTab-root {
    ${H5Styles};
    font-family: ${GLOBAL.BRAND_FONT_FAMILY_SECOND};
    text-transform: none;
    min-width: 50%;

    padding: calc(${SPACE.X1} + ${SPACE.X0});
    @media (min-width: ${BREAKPOINTS.LG}px) {
      padding: ${SPACE.X1};
    }

    &:first-child {
      box-shadow: inset -4px -4px 5px -3px ${COLORS.GREY_FOUR};
    }
    &:last-child {
      box-shadow: inset 4px -4px 5px -3px ${COLORS.GREY_FOUR};
    }
    &.Mui-selected {
      box-shadow: none;
    }
    &.Mui-focusVisible {
      background-color: ${COLORS.GREY_FOUR};
      box-shadow: 0 5px 5px 5px ${COLORS.GREY_SIX};
      border-radius: 1px;
    }
    &:hover {
      background-color: ${COLORS.GREY_FOUR};
      box-shadow: 0 5px 5px 5px ${COLORS.GREY_SIX};
      border-radius: 1px;
    }
  }
`;

const TabsWrapper = styled.div`
  background-color: ${COLORS.WHITE};
  width: 100%;
  box-shadow: ${BOX_SHADOW.DEFAULT};
  border-radius: 0;
`;

const Header = styled(H5)`
  margin-bottom: ${SPACE.X1};
  letter-spacing: 0;
`;

const TabPanel = styled.div`
  text-align: center;

  margin-top: ${SPACE.X2};
  @media (min-width: ${BREAKPOINTS.MD}px) {
    margin-top: calc(${SPACE.X1} + ${SPACE.X0});
  }
`;

const SearchButtonWrapper = styled.div`
  @media (min-width: ${BREAKPOINTS.LG}px) {
    width: 50%;
    margin: auto;
  }

  margin-top: ${SPACE.X1};
  margin-bottom: ${SPACE.X3};

  @media (min-width: ${BREAKPOINTS.MD}px) {
    margin-top: ${SPACE.X0};
    margin-bottom: ${SPACE.X2};
  }
`;

const ErrorText = styled.p`
  color: ${COLORS.DANGER_RED_EIGHT};
  font-size: ${FONT_SIZE.SMALL};
  margin-bottom: 0;
`;

const NumberPlateErrorText = styled(ErrorText)`
  margin-top: ${SPACE.X1};

  @media (min-width: ${BREAKPOINTS.MD}px) {
    margin-top: ${SPACE.X0};
  }
`;

const StyledTextField = styled(TextField)`
  .MuiInput-underline {
    ::before {
      border-bottom: none;
    }
    &::before {
      border-bottom: none;
    }
    :hover:not(.Mui-disabled):before {
      border-bottom: none;
    }
  }

  .MuiInput-underline:after {
    border-bottom: none;
  }

  &.MuiFormControl-root {
    width: 100%;

    label {
      position: absolute !important;
      clip: rect(1px, 1px, 1px, 1px);
      width: 1px !important;
      height: 1px !important;
      padding: 0 !important;
      border: 0 !important;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  .MuiInputBase-root {
    border: solid 1px ${COLORS.BRAND_BLUE_SIX};
    border-radius: 0;
    box-shadow: ${BOX_SHADOW.DEFAULT};
    margin: 0;
  }
`;

const NumberPlateTextField = styled(StyledTextField)`
  .MuiInputBase-root {
    border: solid 1px ${COLORS.BRAND_BLUE_SIX};
    border-radius: 0;
    box-shadow: ${BOX_SHADOW.DEFAULT};
    margin: 0;

    input {
      ${H5Styles};
      font-family: ${GLOBAL.BRAND_FONT_FAMILY_SECOND};
      color: ${COLORS.GREY_ELEVEN};
      ::placeholder {
        color: ${COLORS.GREY_SEVEN};
        opacity: 1;
        user-select: none;
      }
      line-height: normal;
      width: 10px;
      :focus {
        outline: none;
      }

      flex-grow: 1;
      @media (min-width: ${BREAKPOINTS.MD}px) {
        padding-top: 12px;
        padding-bottom: 12px;
      }
      @media (min-width: ${BREAKPOINTS.LG}px) {
        padding-top: ${SPACE.X0};
        padding-bottom: ${SPACE.X0};
      }

      padding-top: ${SPACE.X1};
      padding-bottom: ${SPACE.X1};
      text-align: center;
    }
  }
`;

const DropdownOption = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  &:hover: {
    backgroundcolor: ${COLORS.BRAND_BLUE_FOUR};
  }
  &:focus: {
    backgroundcolor: ${COLORS.BRAND_BLUE_FOUR};
  }
  &:active: {
    backgroundcolor: ${COLORS.BRAND_BLUE_THREE};
  }
`;

const H5Second = styled(H5)`
  font-family: ${GLOBAL.BRAND_FONT_FAMILY_SECOND};
`;

const ExampleText = styled.p`
  font-family: ${GLOBAL.BRAND_FONT_FAMILY_SECOND};
  font-size: ${FONT_SIZE.X_SMALL};
  @media (min-width: ${BREAKPOINTS.MD}px) {
    font-size: 14px;
  }
  @media (min-width: ${BREAKPOINTS.LG}px) {
    font-size: ${FONT_SIZE.MEDIUM};
  }
  margin-bottom: ${SPACE.X0};
`;

const DisplayContents = styled.span`
  display: contents;
  width: 100%;
`;

const StyledSelect = {
  // container: (styles) => ({
  //   ...styles,
  //   backgroundColor: COLORS.WHITE,
  //   border: `1px solid ${COLORS.BLACK}`,
  // }),
  // valueContainer: (styles) => ({
  //   ...styles,
  //   padding: '0',
  // }),
  // control: (styles, { isFocused }) => ({
  //   ...styles,
  //   backgroundColor: isFocused ? COLORS.WHITE : 'transparent',
  //   outline: `1px solid ${isFocused ? COLORS.BRAND_BLUE_SIX : 'transparent'}`,
  //   borderRadius: '0',
  //   fontFamily: GLOBAL.BRAND_FONT_FAMILY_SECOND,
  //   wordBreak: 'normal',
  //   whiteSpace: 'normal',
  //   padding: '0.25rem',
  // }),

  // menu: (styles) => ({
  //   ...styles,
  //   fontFamily: GLOBAL.BRAND_FONT_FAMILY_SECOND,
  //   fontSize: FONT_SIZE.X_LARGE,
  // }),

  // menuList: (styles) => ({
  //   ...styles,
  //   textAlign: 'left',
  // }),

  // option: (styles, { isFocused }) => ({
  //   ...styles,
  //   color: `${COLORS.BLACK}`,
  //   backgroundColor: isFocused ? `${COLORS.BRAND_BLUE_THREE}` : 'transparent',
  //   ':hover': { backgroundColor: `${COLORS.BRAND_BLUE_FOUR}` },
  //   ':focus': { backgroundColor: `${COLORS.BRAND_BLUE_FOUR}` },
  //   ':active': { backgroundColor: `${COLORS.BRAND_BLUE_THREE}` },
  // }),
  // input: (styles) => ({
  //   ...styles,
  //   ...search(),
  // }),
  placeholder: (styles) => ({ ...styles }),
  dropdownIndicator: () => ({ display: 'none' }),
  indicatorSeparator: () => ({ display: 'none' }),
};

export {
  DisplayContents,
  DropdownOption,
  ErrorText,
  ExampleText,
  H5Second,
  Header,
  OptionIcon,
  SearchIcon,
  StyledTab,
  StyledTabs,
  StyledTextField,
  StyledSelect,
  TabsWrapper,
  Wrapper,
  TabPanel,
  SearchButtonWrapper,
  NumberPlateTextField,
  NumberPlateErrorText,
};
