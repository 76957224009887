import { Checkbox as MuiCheckbox, FormControlLabel, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { COLORS, SPACE } from '../../constants';

/*
 Customizing Material UI Checkbox Icon requires the use of makeStyles styling solution.
*/
const useStyles = makeStyles((theme) => ({
  icon: {
    borderRadius: 0,
    boxShadow: 'inset 0 0 0 1px #949494, inset 0 -1px 0 #949494',
    width: 18,
    height: 18,
    [theme.breakpoints.up('md')]: {
      width: 24,
      height: 24,
    },
  },
  checkedIcon: {
    backgroundColor: COLORS.BLACK,
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 18,
      height: 18,
      [theme.breakpoints.up('md')]: {
        width: 24,
        height: 24,
      },
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
  },
}));

const StyledCheckbox = styled(MuiCheckbox)`
  cursor: pointer;

  &.MuiButtonBase-root {
    padding: 0;
    margin: 0;
    margin-right: ${SPACE.X0};
    cursor: auto;

    &.MuiCheckbox-root {
      &:hover {
        box-shadow: inset 0 0 0 2px ${COLORS.BRAND_BLUE_SIX};
        border-radius: 1px;
      }
      &:focus {
        box-shadow: inset 0 0 0 2px ${COLORS.BRAND_BLUE_SIX};
        border-radius: 1px;
      }
      &.Mui-focusVisible {
        box-shadow: inset 0 0 0 2px ${COLORS.BRAND_BLUE_SIX};
        border-radius: 1px;
      }
    }
  }

  .MuiIconButton-label {
    cursor: pointer;

    & input {
      cursor: pointer;
    }
  }
`;

const StyledFormControlLabel = styled(({ isMobile, ...rest }) => <FormControlLabel {...rest} />)`
  .MuiFormControlLabel-label {
    font-size: unset;
    order: ${({ isMobile }) => (isMobile ? 1 : 2)};
  }

  .MuiButtonBase-root {
    order: ${({ isMobile }) => (isMobile ? 2 : 1)};
  }
`;

const Checkbox = ({ handleChange, label, checked, isMobile }) => {
  const classes = useStyles();
  return (
    <StyledFormControlLabel
      {...{ isMobile }}
      control={
        <StyledCheckbox
          checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
          icon={<span className={classes.icon} />}
          color="default"
          label={label}
          onClick={(event) => handleChange(event.target.checked)}
          checked={checked}
          tabIndex={0}
        />
      }
      label={label}
    />
  );
};

Checkbox.propTypes = {
  isMobile: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  label: PropTypes.node.isRequired,
  checked: PropTypes.bool,
};

Checkbox.defaultProps = { checked: false, isMobile: false };

export default Checkbox;
