import { clone, filter, first, isEmpty, map } from 'lodash';

const isNotEmpty = (arr = []) => !isEmpty(arr);

const transformImage = (image) => (isNotEmpty(image) ? first(image).url : undefined);

const transformMedia = (arr = []) => {
  const { kind, ...media } = first(arr) || {};

  if (kind === 'image') {
    return {
      kind,
      alt: media.title,
      src: media.url,
    };
  }

  if (kind === 'video') {
    return {
      kind,
      src: media.url,
      type: media.mimeType,
      posterImage: media.posterImage,
      captions: media.captions,
    };
  }

  return undefined;
};

const transformButton = (ctaButton) => {
  const { isEnabled, label, internalEntry, externalUrl, pdfDocument } = first(ctaButton) || {};

  if (!isEnabled) return undefined;

  if (isNotEmpty(internalEntry)) {
    return {
      label,
      href: `/${first(internalEntry).uri}`,
    };
  }

  return {
    label,
    href: isNotEmpty(pdfDocument) ? first(pdfDocument).url : externalUrl,
  };
};

const transformContentMatrix = (contentMatrix) => {
  const transformedContentMatrix = clone(
    filter(contentMatrix, (contentMatrixBlock) => !!contentMatrixBlock.typeHandle)
  );

  return map(transformedContentMatrix, (contentMatrixBlock) => {
    switch (contentMatrixBlock.typeHandle) {
      case 'contentBlock':
        return contentMatrixBlock;
      case 'contentGridBlock':
        return contentMatrixBlock;
      case 'contentMediaBlock':
        return {
          ...contentMatrixBlock,
          media: isNotEmpty(contentMatrixBlock.media) ? transformMedia(contentMatrixBlock.media) : undefined,
          callToAction: transformButton(contentMatrixBlock.ctaButton),
        };
      case 'pageHeaderBlock':
        return {
          ...contentMatrixBlock,
          image: isNotEmpty(contentMatrixBlock.image)
            ? {
                url: first(contentMatrixBlock.image).url,
                title: first(contentMatrixBlock.image).title,
              }
            : undefined,
          backgroundImage: {
            ...first(contentMatrixBlock.backgroundImage),
            url: transformImage(contentMatrixBlock.backgroundImage),
          },
          category: contentMatrixBlock.preHeading,
        };
      case 'safetyFeatureShowcaseBlock':
        return {
          ...contentMatrixBlock,
          safetyFeature: isNotEmpty(contentMatrixBlock.safetyFeature)
            ? first(contentMatrixBlock.safetyFeature)
            : undefined,
          backgroundImage: isNotEmpty(contentMatrixBlock.backgroundImage)
            ? {
                url: first(contentMatrixBlock.backgroundImage).url,
                description: first(contentMatrixBlock.backgroundImage).title,
              }
            : undefined,
        };
      default:
        return contentMatrixBlock;
    }
  });
};

export { transformContentMatrix };
