import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiTooltip from '@material-ui/core/Tooltip';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import styled from 'styled-components';
import MuiIconButton from '@material-ui/core/IconButton';

import IconWithBackground from '../icon-with-background';
import { iconNames } from '../icons';
import { COLORS, SPACE } from '../../constants';

const StyledMuiTooltip = withStyles({
  tooltip: {
    maxWidth: 200,
    padding: SPACE.X0,
    borderRadius: 0,
  },
})(MuiTooltip);

const IconButton = styled(MuiIconButton)`
  &.MuiButtonBase-root {
    padding: 0;
  }
  &.MuiIconButton-root {
    :hover {
      box-shadow: 0 0px 8px 2px ${COLORS.BLUE_EIGHT};
    }
    &.Mui-focusVisible {
      box-shadow: 0 0px 8px 2px ${COLORS.BLUE_EIGHT};
    }
  }
`;

const Tooltip = (props) => {
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <StyledMuiTooltip
        arrow
        onClose={handleTooltipClose}
        open={open}
        aria-expanded={open}
        aria-describedby={null}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        interactive
        PopperProps={{ disablePortal: true, tabIndex: '0' }}
        {...props}
      >
        <IconButton onClick={handleTooltipOpen} aria-label="tooltip" disableFocusRipple disableRipple>
          <IconWithBackground {...{ iconName: iconNames.questionMark, backgroundColor: COLORS.BRAND_YELLOW_SEVEN }} />
        </IconButton>
      </StyledMuiTooltip>
    </ClickAwayListener>
  );
};

export default Tooltip;
